import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from '../hooks/useRefreshToken';
import useAuth from '../hooks/useAuth';
import localforage from "localforage";

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth, setAuth } = useAuth();

    useEffect(() => {
        let isMounted = true;

        const verifyRefreshToken = async () => {
            try {
                // await refresh();

                const user = await localforage.getItem("online_user")
                const token = await localforage.getItem("online_token")
                const refreshToken = await localforage.getItem("online_refreshToken")
                const roles = await localforage.getItem("online_roles")

                setAuth(prev => ({
                    ...prev,
                    user, token, refreshToken, roles
                }))

                return true
            }
            catch (err) {
                console.error(err);
            }
            finally {
                isMounted && setIsLoading(false);
            }
        }

        !auth?.token ? verifyRefreshToken() : setIsLoading(false);

        return () => isMounted = false;
    }, [])

    return isLoading
        ? <p>Loading...</p>
        : <Outlet />
}

export default PersistLogin
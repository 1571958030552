import {
  Box,
  Center,
  Circle,
  Heading,
  Image,
  List,
  ListIcon,
  ListItem,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  UnorderedList,
} from '@chakra-ui/react';
import logo from '../../assets/app_logo.png';
import { FaRegCircle } from 'react-icons/fa';

export default function MajorMechanics() {
  return (
    <Box py={4} w={['full', '2xl']} m="auto">
      <Center textAlign="center" mb={4}>
        <Image src={logo} w={['150px', '200px']} alt="MARBLE Color Game" />
      </Center>
      <Heading
        as="h1"
        size={['md', 'lg']}
        textAlign="center"
        sx={{ WebkitTextStroke: '0.5px rgb(237 126 52)' }}
        textShadow="2px 2px 1px rgb(237 126 52)"
      >
        MAJOR JACKPOT GAME MECHANICS
      </Heading>

      <Box padding={4}>
        <UnorderedList>
          <ListItem>
            MAJOR JACKPOT betting is only available every start of the first
            race per event.
          </ListItem>
          <ListItem>
            Player will choose a possible color combination to win per race in
            the major jackpot betting console.{' '}
            <Text as="span" fontStyle="italic" color="#f9cf84">
              See below color combinations:
            </Text>
            <List>
              <ListItem>
                <ListIcon as={FaRegCircle} w="2" color="red" />
                <Text as="span" fontWeight="medium" color="red">
                  RED
                </Text>
              </ListItem>
              <ListItem>
                <ListIcon as={FaRegCircle} w="2" color="blue" />
                <Text as="span" fontWeight="medium" color="blue">
                  BLUE
                </Text>
              </ListItem>
              <ListItem>
                <ListIcon as={FaRegCircle} w="2" color="green" />
                <Text as="span" fontWeight="medium" color="green">
                  GREEN
                </Text>
              </ListItem>
              <ListItem>
                <ListIcon as={FaRegCircle} w="2" color="yellow" />
                <Text as="span" fontWeight="medium" color="yellow">
                  YELLOW
                </Text>
              </ListItem>
              <ListItem>
                <ListIcon as={FaRegCircle} w="2" color="white" />
                <Text as="span" fontWeight="medium" color="white">
                  WHITE
                </Text>
              </ListItem>
              <ListItem>
                <ListIcon as={FaRegCircle} w="2" color="rgb(255 153 254)" />
                <Text as="span" fontWeight="medium" color="rgb(255 153 254)">
                  PINK
                </Text>
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            There are six (6) race selections per major jackpot betting.
          </ListItem>
          <ListItem>
            <Text>
              Player will win MAJOR JACKPOT PRIZE if all selected possible
              colors per race are come out.
            </Text>
            <List>
              <ListItem>
                <ListIcon as={FaRegCircle} w="2" />
                <Text as="span">Example:</Text>
                <TableContainer>
                  <Table
                    borderColor="white"
                    sx={{ borderCollapse: 'collapse' }}
                  >
                    <Tbody>
                      <Tr>
                        {[1, 2, 3, 4, 5, 6].map(item => (
                          <Td
                            border="1px solid white"
                            p={2}
                            fontWeight="medium"
                          >
                            RACE {item}
                          </Td>
                        ))}
                      </Tr>
                      <Tr>
                        {[
                          'RED',
                          'BLUE',
                          'PINK',
                          'GREEN',
                          'YELLOW',
                          'WHITE',
                        ].map(item => (
                          <Td border="1px solid white" p={2}>
                            <Center>
                              <Circle
                                h="25px"
                                w="25px"
                                bg={`url(/150x150pxballs/BALLS-${item}.png)`}
                                overflow="hidden"
                                bgSize="contain"
                              ></Circle>
                            </Center>
                          </Td>
                        ))}
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            The MAJOR JACKPOT PRIZE will be automatically carry over in the next
            event if there are no winners in the current or previous event.
          </ListItem>
          <ListItem>
            The MAJOR JACKPOT PRIZE will be distributed equally based on the
            number of winners.
          </ListItem>
          <ListItem>
            The system will automatically deduct the assigned percentage from
            the total winnings as company’s commission.
          </ListItem>
          <ListItem>
            There is an assigned MAJOR JACKPOT bet which will be set by the
            company/operator before the start of every event.
          </ListItem>
          <ListItem>
            The company/operator will have an option to set initial MAJOR
            JACKPOT PRIZE per event.
          </ListItem>
        </UnorderedList>
      </Box>
    </Box>
  );
}

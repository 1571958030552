import React, { useEffect, useRef } from 'react';
// import TheoPlayer from './THEOplayerWrapper';
// import Craffstream from './Craffstream'
// import MillicastViewer from './MillicastViewer'
import { AspectRatio, Box, HStack, Text, VStack } from '@chakra-ui/react';
import { WebRTCPlayer } from '@eyevinn/webrtc-player';
// import './Whep.module.css';

const VideoPlayer = props => {
  // const _videoTypes = ['MILLICAST', 'THEO', 'IFRAME', 'CRAFFSTREAM'];
  // const validTypes = new Set(_videoTypes);

  // function generateIframe(src) {
  //   return { __html: src };
  // }

  // const noVideo = !validTypes.has(props.videoType);

  // if (props?.videoType === _videoTypes[0]) {
  //   return (
  //     <Box height="full" width="full">
  //       <MillicastViewer {...props} />{' '}
  //     </Box>
  //   );
  // }

  // if (props?.videoType === _videoTypes[1]) {
  //   return (
  //     <Box height="full" width="full">
  //       <TheoPlayer videosrc={props.videoSrc} />
  //     </Box>
  //   );
  // }

  // if (props?.videoType === _videoTypes[2]) {
  //   <Box height="full" width="full">
  //     <Box
  //       w={'full'}
  //       h={'full'}
  //       dangerouslySetInnerHTML={generateIframe(props.videoSrc)}
  //     ></Box>
  //   </Box>;
  // }

  // if (props?.videoType === _videoTypes[3]) {
  //   return (
  //     <Box height="full" width="full">
  //       <Craffstream {...props} />
  //     </Box>
  //   );
  // }

  // return (
  //   <Box height="full" width="full">
  //     <VStack
  //       bg={'black'}
  //       alignItems={'center'}
  //       alignContent={'center'}
  //       justifyContent={'center'}
  //       h={'full'}
  //       bgSize={['contain', 'auto']}
  //       w={'full'}
  //       bgRepeat={'no-repeat'}
  //       bgPos={'center'}
  //       textAlign={'center'}
  //     >
  //       <Text as="p" color="gold">
  //         NO VIDEO
  //       </Text>
  //     </VStack>
  //   </Box>
  // );

  return (
    <Box height="full" width="full">
      <whep-video
        src="https://customer-gc73aqvl9oq83lia.cloudflarestream.com/359346f60f669e61d8b1efb7353292da/webRTC/play"
        muted
        autoplay
      />
    </Box>
  );
};

const WebRTCPlayerComponent = ({
  channelUrl = 'https://customer-gc73aqvl9oq83lia.cloudflarestream.com/359346f60f669e61d8b1efb7353292da/webRTC/play',
}) => {
  const videoRef = useRef(null); // Create a ref for the video element
  const playerRef = useRef(null); // Store the WebRTCPlayer instance

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      // Initialize the WebRTCPlayer instance
      const player = new WebRTCPlayer({
        video: videoElement,
        type: 'whep',
        statsTypeFilter: '^candidate-*|^inbound-rtp',
      });

      playerRef.current = player; // Store the player instance in ref

      // Load the stream and unmute
      player
        .load(new URL(channelUrl))
        .then(() => {
          player.unmute();
        })
        .catch(err => {
          console.error('Error loading WebRTC stream:', err);
        });

      // Set up event listeners for player
      player.on('no-media', () => {
        console.log('media timeout occurred');
      });

      player.on('media-recovered', () => {
        console.log('media recovered');
      });

      player.on('stats:inbound-rtp', report => {
        if (report.kind === 'video') {
          console.log(report);
        }
      });

      // Cleanup when component unmounts
      return () => {
        if (playerRef.current) {
          playerRef.current.destroy(); // Cleanup the player instance
          playerRef.current = null;
        }
      };
    }
  }, [channelUrl]); // Re-run when channelUrl changes

  return (
    <Box minH={['210px', '360px']} height="full" width="full">
      <video
        id="whep-video"
        ref={videoRef}
        autoPlay
        playsInline
        width="100%"
        height="100%"
      ></video>
      {/* Use ref to attach video element */}
    </Box>
  );
};

export default React.memo(WebRTCPlayerComponent);

import {
  Box,
  Center,
  Heading,
  Image,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import logo from '../../assets/app_logo.png';

export default function RegularMechanics() {
  return (
    <Box py={4} w={['full', '2xl']} m="auto">
      <Center textAlign="center" mb={4}>
        <Image src={logo} w={['150px', '200px']} alt="MARBLE Color Game" />
      </Center>
      <Heading
        as="h1"
        size={['md', 'lg']}
        textAlign="center"
        sx={{ WebkitTextStroke: '0.5px white' }}
        color="rgb(91 155 213)"
        textShadow="1px 1px 1px rgb(157 195 230)"
      >
        REGULAR RACE GAME MECHANICS
      </Heading>
      <Box padding={4}>
        <UnorderedList>
          <ListItem>
            Player will choose a color to bet in the betting console.
          </ListItem>
          <ListItem>
            There are six (6) colors to choose in the betting console, that is{' '}
            <Text as="span" color="red" fontWeight="medium">
              RED
            </Text>
            ,{' '}
            <Text as="span" color="blue" fontWeight="medium">
              BLUE
            </Text>
            ,{' '}
            <Text as="span" color="green" fontWeight="medium">
              GREEN
            </Text>{' '}
            ,{' '}
            <Text as="span" color="yellow" fontWeight="medium">
              YELLOW
            </Text>{' '}
            ,{' '}
            <Text as="span" color="white" fontWeight="medium">
              WHITE
            </Text>{' '}
            &{' '}
            <Text as="span" color="rgb(255 153 254)" fontWeight="medium">
              PINK
            </Text>
            .
          </ListItem>
          <ListItem>Each color will be placed in the Race Track.</ListItem>
          <ListItem>
            Each color in the Race Track is equal in size and almost have
            similar in weights.
          </ListItem>
          <ListItem>
            The first color to reach the finish line after the second round of
            racing will be declared the winner of the race.
          </ListItem>
          <ListItem>
            The declared winning color and winners of the race will take all the
            bets based on the ODDS.
          </ListItem>
          <ListItem>
            The system will automatically deduct the assigned percentage from
            the total winnings as company’s commission.
          </ListItem>
          <ListItem>Each race has an interval of 1-2 minutes.</ListItem>
          <ListItem>
            There is a minimum and maximum bet which will be set by the
            company/operator before the start of every event/race.
          </ListItem>
        </UnorderedList>
      </Box>
    </Box>
  );
}
